import React from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";

import SideNav, {NavIcon, NavItem, NavText} from "@trendmicro/react-sidenav";
import routes from "../../navigation/routes";
import {Link} from "react-router-dom";
import {Link as ScrollLink} from 'react-scroll';
import Translator from "../i18n/Translator";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: true,
            isCardVisible: false
        };
    }

    render() {
        const CustomNavLink = ({to, children, icon}) => (
            <Link to={to} className="remove-link-style">
                <NavItem eventKey={to}>
                    <NavIcon>
                        <i className={icon}></i>
                    </NavIcon>
                    <NavText>{children}</NavText>
                </NavItem>
            </Link>
        );

        const CustomNavScrollLink = ({to, children, icon}) => (
            <ScrollLink to={to} smooth={true} duration={200} className="remove-link-style">
                <NavItem eventKey={to}>
                    <NavIcon>
                        <i className={icon}></i>
                    </NavIcon>
                    <NavText>{children}</NavText>
                </NavItem>
            </ScrollLink>
        );

        return (
            <SideNav expanded={this.state.isVisible} className="sidebar-fixed">
                <SideNav.Toggle
                    onClick={() => {
                        this.setState({isVisible: !this.state.isVisible});
                    }}
                />
                <SideNav.Nav defaultSelected={routes.ROOT}>
                    <NavItem eventKey={routes.ROOT}>
                        <CustomNavLink to={routes.ROOT} icon={"bi bi-house-door-fill"}>
                            <Translator path={'sidebar.home'}/>
                        </CustomNavLink>
                    </NavItem>
                    <NavItem eventKey={null}>
                        <CustomNavScrollLink to={routes.HOW_IT_WORKS} icon={"bi bi-lightbulb-fill"}>
                            <Translator path={'sidebar.how_it_works'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.API_KEY}>
                        <CustomNavScrollLink to={routes.API_KEY} icon={"bi bi-folder-fill"}>
                            <Translator path={'sidebar.getting_started'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    {this.state.isCardVisible ?
                        <NavItem eventKey={routes.ACTIVATE}>
                            <CustomNavScrollLink to={routes.ACTIVATE} icon={null}>
                                <Translator path={'sidebar.getting_started'}/>
                            </CustomNavScrollLink>
                        </NavItem> : null}
                    <NavItem eventKey={routes.GET_COLLATERAL}>
                        <CustomNavScrollLink to={routes.GET_COLLATERAL} icon={null}>
                            <Translator path={'endpoint.get_collateral'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.DUE_DATE}>
                        <CustomNavScrollLink to={routes.DUE_DATE} icon={null}>
                            <Translator path={'endpoint.change_due_date'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.RELEASE}>
                        <CustomNavScrollLink to={routes.RELEASE} icon={null}>
                            <Translator path={'endpoint.release_collateral'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.GET_ALL}>
                        <CustomNavScrollLink to={routes.GET_ALL} icon={null}>
                            <Translator path={'endpoint.get_all_collaterals'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.GET_BY_TAG}>
                        <CustomNavScrollLink to={routes.GET_BY_TAG} icon={null}>
                            <Translator path={'endpoint.get_all_by_tag'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.GET_OFFLINE_UNLOCK}>
                        <CustomNavScrollLink to={routes.GET_OFFLINE_UNLOCK} icon={null}>
                            <Translator path={'endpoint.offline_unlock'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.RELEASE_COLLATERAL}>
                        <CustomNavScrollLink to={routes.RELEASE_COLLATERAL} icon={null}>
                            <Translator path={'endpoint.release'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.UPDATE_PRODUCT_ITEM_ID}>
                        <CustomNavScrollLink to={routes.UPDATE_PRODUCT_ITEM_ID} icon={null}>
                            <Translator path={'endpoint.update_product_item_id'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.SEND_MESSAGE}>
                        <CustomNavScrollLink to={routes.SEND_MESSAGE} icon={null}>
                            <Translator path={'endpoint.send_message'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.START_BLINK_REMINDER}>
                        <CustomNavScrollLink to={routes.START_BLINK_REMINDER} icon={null}>
                            <Translator path={'endpoint.START_BLINK_REMINDER'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                    <NavItem eventKey={routes.STOP_BLINK_REMINDER}>
                        <CustomNavScrollLink to={routes.STOP_BLINK_REMINDER} icon={null}>
                            <Translator path={'endpoint.STOP_BLINK_REMINDER'}/>
                        </CustomNavScrollLink>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        );
    }
}

export default Sidebar;
